import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "../private/pages/contact";
import Faq from "../private/pages/faq";
import Settings from "../private/pages/settings";
import Start from "../private/pages/start";
import Imprint from "../public/imprint"; // Imprint page component
import Login from "../public/login"; // Login page component
import Page404 from "../public/page404"; // 404 page component
import Privacy from "../public/privacy"; // Privacy page component

const PageRouter = () => {
  return (
    // BrowserRouter is used for client-side routing
    <BrowserRouter>
      {/* Routes component to define different routes */}
      <Routes>
        {/* Route for the root path, renders the Login component */}
        <Route path="/" element={<Login />} />
        {/* Route for the root path, renders the Start component */}
        <Route path="/start" element={<Start />} />
        {/* Route for the root path, renders the Faq component */}
        <Route path="/faq" element={<Faq />} />
        {/* Route for the root path, renders the Contact component */}
        <Route path="/contact" element={<Contact />} />
        {/* Route for the /imprint path, renders the Imprint component */}
        <Route path="/imprint" element={<Imprint />} />
        {/* Route for the /privacy path, renders the Privacy component */}
        <Route path="/privacy" element={<Privacy />} />
        {/* Route for the /privacy path, renders the Privacy component */}
        <Route path="/settings" element={<Settings />} />
        {/* Catch-all route for any other paths not defined, renders the Page404 component */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

// Export PageRouter component as the default export of the module
export default PageRouter;
