import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedFaqGroup, fetchFaq, fetchFaqGroup } from "../../../../store/actions/faq";
import { ColumnContainer, RowContainer } from "../../../styles/containers/styles";
import { PageHead } from "../../../styles/headings";
import Layout from "../../common/layout";
import { Grouping, FaqList, Group, FaqItem, Question, Answer } from "./styels";
const Faq = () => {
   const { t } = useTranslation();
   //to update the page title
   useEffect(() => {
    document.title = `${t("faq")} - Ticket App | Innopark24`;
  }, [t]);
  //dispatch is using to update the state in store
  const dispatch = useDispatch();
  
  //theme color is getting from redux
  const themeColors = useSelector((state) => state.themeColors);

  //selectedGroup varibale to store the selected group
  const selectedGroup = useSelector((state) => state.selectedFAQGroup);

  //this fetching state value from redux store this will update when we use dispatch
  const faqGroup = useSelector((state) => state.faqGroup);
  const faqs = useSelector((state) => state.faq);
  useEffect(() => {
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!faqGroup.data) {
      dispatch(fetchFaqGroup());
      // Dispatch action to fetch FAQs for the first group in the list
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [dispatch, faqGroup]);
  useEffect(() => {
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!faqs.data[selectedGroup]) {
      dispatch(fetchFaq(selectedGroup));
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [dispatch, selectedGroup, faqs.data]);
  const getFaqs = (id) => {
    // Dispatch action to fetch FAQs for the selected group
    if (!faqs.data[id]) {
      dispatch(fetchFaq(id));
    }
    // Set the selected group state
    dispatch(changeSelectedFaqGroup(id));
  };

  return (
    <RowContainer>
      <PageHead>{t("faqTitle")}</PageHead>
      <ColumnContainer>
        <Grouping>
          {faqGroup.data &&
            faqGroup.data.map((item) => {
              return (
                <Group theme={themeColors} className={item.id === selectedGroup && "active"} onClick={() => getFaqs(item.id)} key={item.id}>
                  {item.name}
                </Group>
              );
            })}
        </Grouping>
        <FaqList>
          {faqs.data[selectedGroup] &&
            faqs.data[selectedGroup]
              .sort(() => Math.random() - 0.5)
              .map((item) => {
                return (
                  <FaqItem key={item.question}>
                    <Question>{item.question}</Question>
                    <Answer>{item.answer}</Answer>
                  </FaqItem>
                );
              })}
        </FaqList>
      </ColumnContainer>
    </RowContainer>
  );
};

export default Layout(Faq);
