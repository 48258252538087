import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../elements/form";
import Footer from "../footer";
import { FormContainer, ImageContainer, MainContainer } from "./styles";
import banner from "../../../images/public/banner.png";
//using translation
import { useTranslation } from "react-i18next";
import { clearVehicle, fetchVehicle } from "../../../store/actions/vehicle";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer, RowContainer } from "../../styles/containers/styles";
import Header from "../header";

const Login = (props) => {
  useEffect(() => {
    document.title = `Login - My App`;
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const { t } = useTranslation();
  const formInput = [
    {
      type: "text",
      placeholder: "fileNumber",
      name: "fileNo",
      validation: "fileNumber",
      default: "5345345",
      label: "fileNumber",
      minimum: 5,
      maximum: 20,
      required: true,
    },
    {
      type: "text",
      placeholder: "licensePlate",
      name: "licensePlate",
      validation: "licensePlate",
      default: "ABC-DE 123",
      label: "licensePlate",
      minimum: 0,
      maximum: 30,
      required: true,
    },
  ];
  const { setLoaderBox } = props;
  useEffect(() => {
    if (vehicle.data.parkingProgress) {
      navigate("/start");
    }
    setLoaderBox(vehicle.isLoading);
    if (vehicle.error !== null) {
      props.setMessage({ type: 1, content: t(vehicle.error), proceed: "Okay" });
      dispatch(clearVehicle());
    }
  }, [vehicle, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    dispatch(fetchVehicle(post));
  };

  return (
    <MainContainer>
      <Header> </Header>
      <ColumnContainer className="login">
        <FormContainer>
          <AutoForm
            useCaptcha={false}
            formType={"post"}
            description={t("loginDescription")}
            header={t("loginHead")}
            formInput={formInput}
            submitHandler={submitChange}
            button={t("validate")}
            isOpenHandler={isCreatingHandler}
            isOpen={true}
            plainForm={true}
          ></AutoForm>
        </FormContainer>
        <ImageContainer className="hm">
          <img src={banner} alt="logo" />
        </ImageContainer>
      </ColumnContainer>
      <RowContainer className="white">
        <h1>{t("faqRed")}</h1>
        <Link to="https://www.innopark24.de/faq">{t("faq")}</Link>
      </RowContainer>
      <Footer></Footer>
    </MainContainer>
  );
};

export default withLayout(Login);
