import styled from "styled-components";

export const Concerns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    &.shrink {
      flex-wrap: nowrap;
      max-width: 100%;
      display: -webkit-box;
      overflow: auto;
    }
  }
`;
export const Concern = styled.div`
  display: flex;
  padding: 5px 10px;
  width: 150px;
  height: 150px;
  margin: 0px 15px 15px 0px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: ${(props) => props.theme.themeBackground};
    color: ${(props) => props.theme.themeForeground};
    transform: scale(1);
  }
  @media (max-width: 768px) {
    width: calc(50% - 25px);

    &.active {
      transform: scale(1);
    }
    &:nth-child(odd) {
      margin: 0px 5px 10px 0px;
    }
    &:nth-child(even) {
      margin: 0px 0 0px 5px;
    }
    && {
      /* Styles to apply when parent has class shrink */
      .shrink & {
        /* Styles for Item when parent has class shrink */
        margin: 5px 5px 15px;
      }
      .shrink &:first-child {
        /* Styles for Item when parent has class shrink */
        margin: 5px 5px 15px 0;
      }
    }
  }
  &.shrink {
    width: auto;
    height: auto;
  }
  svg {
    font-size: 30px;
  }
`;
export const Type = styled.div`
  display: flex;
  padding: 5px 10px;
  width: 150px;
  height: 150px;
  margin: 0px 15px 15px 0px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  align-items: center;
  background: white;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  svg {
    margin-right: 10px;
    display: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.themeBackground};
    color: ${(props) => props.theme.themeForeground};
  }
  &.active {
    background-color: ${(props) => props.theme.themeBackground};
    color: ${(props) => props.theme.themeForeground};
    svg {
      margin-right: 10px;
      display: inline;
    }
  }
  &.shrink {
    width: auto;
    height: auto;
  }
  @media (max-width: 768px) {
    max-width: 70%;
  }
`;
export const Label = styled.label`
  justify-content: left;
  display: flex;
  align-items: flex-start;
  margin-bottom:30px;
  &.sticky {
    @media (max-width: 768px) {
      position: sticky;
      bottom: 0;
      background-color: white;
      border-radius: 12px;
      padding: 10px;
    }
  }
`;
export const CheckBox = styled.input`
  height: 15px;
  width: 15px;
`;
