import axios from "axios";
import { GetAccessToken } from "../authentication";

/**
 * Sends a POST request with form data or JSON payload.
 *
 * @param {Object} fields - The data to send in the request.
 * @param {string} url - The URL to send the request to.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
const postData = async (fields, url) => {
  try {
    const token = GetAccessToken();
    const formData = new FormData();
    let isUploading = false;
    // Append fields to form data and check for files
    for (const [key, value] of Object.entries(fields)) {
      if (typeof value === "object") {
        isUploading = true;
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    const response = await axios.post(`${process.env.REACT_APP_API + url}`, formData, {
      headers: {
        "Content-Type": isUploading ? "multipart/form-data" : "application/json",
        authorization: "Bearer " + token,
      },
    });

    return { status: response.status, data: response.data };
  } catch (error) {
    throw new Error(`Failed to POST data: ${error.message}`);
  }
};

/**
 * Sends a PUT request with form data or JSON payload.
 *
 * @param {Object} fields - The data to send in the request.
 * @param {string} url - The URL to send the request to.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
const putData = async (fields, url) => {
  try {
    const token = GetAccessToken();
    const formData = new FormData();
    let isUploading = false;

    // Append fields to form data and check for files
    for (const [key, value] of Object.entries(fields)) {
      if (typeof value === "object") {
        isUploading = true;
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }

    const response = await axios.put(`${process.env.REACT_APP_API + url}`, formData, {
      headers: {
        "Content-Type": isUploading ? "multipart/form-data" : "application/json",
        authorization: "Bearer " + token,
      },
    });

    return { status: response.status, data: response.data };
  } catch (error) {
    throw new Error(`Failed to PUT data: ${error.message}`);
  }
};

/**
 * Sends a GET request with query parameters.
 *
 * @param {Object} params - The query parameters to send in the request.
 * @param {string} url - The URL to send the request to.
 * @returns {Promise<Object>} - A Promise that resolves to the response data.
 */
const getData = async (params, url) => {
  try {
    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const token = GetAccessToken();
    const response = await axios.get(`${process.env.REACT_APP_API + url}?${queryString}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    if (error.response?.status === 401) {
      return "validationFailed";
    } else {
      return (`Failed to GET data: ${error.message}`);
    }
  }
};

export { getData, postData, putData };
