import FormInput from "../input";
import { Content, Footer, Overlay, Page } from "./styles";

const Message = (props) => {
  const closeModal = () => {
    typeof props.meessage.onClose === "function" && props.meessage.onClose();
    props.closeMessage();
  };
  return (
    <Overlay>
      <Page>
        <Content>{props.meessage.content}</Content>
        <Footer>
          <FormInput type="close" value={props.meessage.type === 2 ? "Cancel" : props.meessage.okay ? props.meessage.okay : "Okay"} onChange={closeModal} />
          {props.meessage.type === 2 && (
            <FormInput
              type="submit"
              name="submit"
              value={props.meessage.proceed ? props.meessage.proceed : "Proceed"}
              onChange={() => {
                closeModal();
                try {
                  props.meessage.onProceed();
                } catch {}
              }}
            />
          )}
        </Footer>
      </Page>
    </Overlay>
  );
};
export default Message;
