//this file contains the common conatiner styles like ColumnContainer, RowContainer etc

import { Link } from "react-router-dom";
import styled from "styled-components";

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: auto;
  &.shadow {
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  }
  &.login {
    align-items: center;
    padding: 0;
  }
  &.left {
    justify-content: left;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    &.login {
      align-items: center;
      padding: 10px 10px;
    }
  }
`;
export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  &.white {
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 70px;
    flex-basis: auto;
    h1 {
      max-width: 80%;
      font-size: 25px;
    }
    a {
      background: rgb(19, 129, 197);
      border-radius: 12px;
      color: white;
      transition: all 0.5s ease 0s;
      margin-top: 10px;
      max-width: 400px;
      padding: 15px 20px;
      min-width: 155px;
      border-color: black;
      border-style: solid;
      cursor: pointer;
      border-width: 0px;
      text-align: center;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 10px;
    &.white {
      background-color: white;
      align-items: center;
      justify-content: center;
      display: flex;
      padding-bottom: 50px;
      flex-basis: auto;
      h1 {
        max-width: 80%;
        font-size: 17px;
      }
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  &.hide {
    display: none;
  }
`;

export const LinkButton = styled(Link)`
  margin: 5px;
  text-decoration: none;
  background-color: #1381c5;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  &:first-child {
    margin-left: 0;
  }
  svg {
    margin-right: 10px;
    color: white;
  }
`;
