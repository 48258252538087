// its middlewear to handle reducer call to update a state

import { getData } from "../../../backend/api";

const fetchFaqGroup = () => {
  return (dispatch) => {
    getData({}, "faq-groups")
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            {
              type: "FETCH_FAQ_GROUP_SUCCESS",
              payload: response.data,
            },
            {
              type: "SELECT_FAQ_GROUP",
              payload: response.data[0]?.id,
            }
          );
        } else {
          dispatch({
            type: "FETCH_FAQ_GROUP_ERROR",
            payload: "FAQ groups not found!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_FAQ_GROUP_ERROR",
          payload: error,
        });
      });
  };
};
const fetchFaq = (id) => {
  return (dispatch) => {
    getData({}, "faq-group/:id/questions")
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_FAQ_SUCCESS",
            payload: response.data,
            id: id,
          });
        } else {
          dispatch({
            type: "FETCH_FAQ_ERROR",
            payload: "FAQ group not found!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_FAQ_ERROR",
          payload: error,
        });
      });
  };
};
const changeSelectedFaqGroup = (value) => ({
  type: "SELECT_FAQ_GROUP",
  payload: value,
});
export { fetchFaqGroup, fetchFaq, changeSelectedFaqGroup };
