import React, { useState } from "react";
import { logo } from "../../../../../images";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearVehicle } from "../../../../../store/actions/vehicle";
import { Container, Logo, LogoContaner, Logout, MNav, Status, Title, User } from "./styels";
import { menuStatus } from "../../../../../store/actions/common";
import { CloseIcon, LogoutIcon, MenuIcon, ContactIcon, FaqIcon, HomeIcon, SettingsIcon } from "../../../../../icons";
import LanguageTooltip from "../../../../elements/tooltip";
const Header = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [header, setHeader] = useState("Home");
  const [headerText, setHeaderText] = useState("Home");
  const menuCurrentStatus = useSelector((state) => state.menuStatus);
  const navigate = useNavigate();
  useState(() => {
    switch (location.pathname) {
      case "/start":
        setHeader(<HomeIcon />);
        setHeaderText("start");
        break;
      case "/faq":
        setHeader(<FaqIcon />);
        setHeaderText("faq");
        break;
      case "/contact":
        setHeader(<ContactIcon />);
        setHeaderText("contact");
        break;
      case "/settings":
        setHeader(<SettingsIcon />);
        setHeaderText("settings");
        break;
      default:
        break;
    }
  }, [location.pathname]);
  return (
    <Container>
      <LogoContaner>
        <MNav
          onClick={() => {
            dispatch(menuStatus());
          }}
        >
          {menuCurrentStatus ? <CloseIcon /> : <MenuIcon />}
        </MNav>
        <Logo src={logo} alt="logo" />
      </LogoContaner>
      <Status className="hm">
        <Title>
          {header} {t(headerText)}
        </Title>
        <User>{props.vehicle.fileNumber + ` / ` + props.vehicle.licensePlate}</User>
        <Logout
          onClick={() => {
            dispatch(clearVehicle());
            navigate("/");
          }}
        >
          <LogoutIcon />
          {t("logout")}
        </Logout>
        <LanguageTooltip></LanguageTooltip>
      </Status>
    </Container>
  );
};

export default Header;
