import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FooterText, Nav } from "./styels";

const Footer = () => {
  const { t } = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  return (
    <FooterText>
      <Nav theme={themeColors}>
        {/* Link to the home page */}
        <Link target="_blank" to="https://www.innopark24.de/datenschutz">
          {t("privacy")}
        </Link>
        {/* Link to the imprint page */}
        <Link target="_blank" to="https://www.innopark24.de/impressum">
          {t("imprint")}
        </Link>
      </Nav>
    </FooterText>
  );
};

export default Footer;
