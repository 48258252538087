import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoForm from "../../../elements/form";
import { Row, RowContainer } from "../../../styles/containers/styles";
import { PageHead } from "../../../styles/headings";
import Layout from "../../common/layout";
import { Concern, Concerns, Type, CheckBox, Label } from "./styels";
import { useTranslation } from "react-i18next";
import { changeSelectedConcern, fetchContactConcerns } from "../../../../store/actions/contact";
import { postData } from "../../../../backend/api";
import { ContactLargeIcon, TickIcon } from "../../../../icons";
import moment from "moment";
const Contact = (props) => {
  const { t } = useTranslation();
  //to update the page title
  useEffect(() => {
    document.title = `${t("contact")} - Ticket App | Innopark24`;
  }, [t]);
  //dispatch is using to update the state in store
  const dispatch = useDispatch();

  const themeColors = useSelector((state) => state.themeColors);
  const concerns = useSelector((state) => state.contactConcers);
  const selectedConcern = useSelector((state) => state.selectedConcern);
  const [type, setType] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  // const [initialFormInout, setInitialFormInout] = useState([]);
  // const [formInput, setFormInput] = useState(initialFormInout);
  const submitChange = async (post, fields) => {
    try {
      props.setLoaderBox(true);
      let htmlString = "";
      const { data } = props.vehicle;
      const { parkingProgress } = data;
      // Name row
      if (post.title || post.firstName || post.lastName) {
        htmlString += `<tr><th>${t("name")}</th><td>${post.title ? post.title : ""}. ${post.firstName ? post.firstName : ""} ${post.lastName ? post.lastName : ""}</td></tr>`;
      }

      // File number row
      if (parkingProgress.fileNumber) {
        htmlString += `<tr><th>${t("fileNumber")}</th><td>${parkingProgress.fileNumber}</td></tr>`;
      }

      // License plate row
      if (parkingProgress.licensePlate) {
        htmlString += `<tr><th>${t("licensePlate")}</th><td>${parkingProgress.licensePlate}</td></tr>`;
      }

      // Post rows
      fields.forEach((item) => {
        if (["licensePlate", "fileNumber", "title", "firstName", "lastName"].includes(item.name) || (typeof post[item.name] === "object" && item.type !== "multiple") || post[item.name].length === 0) {
          return;
        }

        if (item.type === "multiple") {
          htmlString += `<tr><th colspan="2">${t(item.name)}</th></tr>`;
          item.forms.forEach((child, index) => {
            htmlString += `<tr><td colspan="2"><table class="child">`;
            child.forEach((childItem) => {
              htmlString += `<tr><th>${t(childItem.name)}</th><td>${post[item.name][index][childItem.name]}</td></tr>`;
            });
            htmlString += "</table></td></tr>";
          });
        }
        else if (item.type === "date") {
          htmlString += `<tr><th>${t(item.name)}</th><td>${moment(post[item.name]).format("DD.MM.YYYY")}</td></tr>`;
        }
         else {
          htmlString += `<tr><th>${t(item.name)}</th><td>${post[item.name]}</td></tr>`;
        }
      });

      // Final rows
      htmlString += `<tr><th>${t("parkingDate")}</th><td>${parkingProgress.dateOfParkingProcess}</td></tr>`;
      htmlString += `<tr><th>${t("parkingDuration")}</th><td>${parkingProgress.parkingDuration}</td></tr>`;

      // Insert HTML into the page

      const finalData = {
        ...post,
        mail: htmlString,
        formHead: type.formHead,
        subject: ` ${parkingProgress.fileNumber} / ${parkingProgress.licensePlate}  / ${type.title} / ${selectedConcern.concern}`,
      };
      // await SendEmail(post);
      await postData(finalData, "contact/submit")
        .then((response) => {
          if (response.status === 200) {
            props.setMessage({ type: 1, content: response.data.message, proceed: "Thank you!" });
            dispatch(changeSelectedConcern(null));
            setType(null);
            setIsAccepted(false);

            // setFormInput(initialFormInout);
          } else {
            props.setMessage({ type: 1, content: "Somethig went wrong", proceed: "Try Again" });
          }
          props.setLoaderBox(false);
        })
        .catch((error) => {
          props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
          props.setLoaderBox(false);
        });
    } catch (error) {
      props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
      props.setLoaderBox(false);
    }
  };
  const setIsAcceptedHandler = (value) => {
    setIsAccepted(value);
  };
  useEffect(() => {
    // Dispatch action to fetch FAQ group if it doesn't exist
    if (!concerns.data) {
      // Dispatch action to fetch FAQs for the first group in the list
      dispatch(fetchContactConcerns());
    }
    // Re-run the effect when dispatch or faqGroup changes
  }, [dispatch, concerns]);
  useEffect(() => {
    // props.setLoaderBox(false);

    if (selectedConcern && selectedConcern.reason?.length === 1 ? true : false) {
      setType(selectedConcern.reason[0]);
    }
  }, [selectedConcern]);
  return (
    <RowContainer>
      <PageHead>{t("contactTitle")}</PageHead>
      <Concerns className={selectedConcern && "shrink"}>
        {concerns.data &&
          concerns.data.map((item) => {
            return (
              <Concern
                key={item.id}
                theme={themeColors}
                className={selectedConcern?.id === item.id && "active"}
                onClick={() => {
                  dispatch(changeSelectedConcern(item));
                  setType(null);
                  setIsAccepted(false);
                }}
              >
                <ContactLargeIcon />
                <span>{item.concern}</span>
              </Concern>
            );
          })}
      </Concerns>
      {selectedConcern && (
        <Row className={selectedConcern.reason?.length === 1 ? "hide" : ""}>
          <p>{t("selectReason")}</p>
          <Concerns className={type && "shrink"}>
            {selectedConcern.reason &&
              selectedConcern.reason.map((item) => {
                return (
                  <Type
                    key={item.title}
                    theme={themeColors}
                    className={type === item ? "active shrink" : "shrink"}
                    onClick={() => {
                      setType(item);
                      setIsAccepted(false);
                    }}
                  >
                    <TickIcon></TickIcon>
                    <span>{item.title}</span>
                  </Type>
                );
              })}
          </Concerns>
        </Row>
      )}
      {type && selectedConcern && (
        <Row>
          {selectedConcern.concern + `, ` + type.title}
          <p dangerouslySetInnerHTML={{ __html: type.description }}></p>
          {type.form && (
            <Label className="sticky">
              <CheckBox
                checked={isAccepted}
                type="checkbox"
                onChange={() => {
                  setIsAccepted((prev) => !prev);
                }}
              />
              {t("agreement")}
            </Label>
          )}
        </Row>
      )}

      {type && isAccepted
        ? type.form && (
            <Row className={!isAccepted && "hide"}>
              <AutoForm
                useCaptcha={false}
                useCheckbox={type.acceptRequired}
                description={type.description}
                key={type.description}
                formType={"post"}
                header={t(type.formHead)}
                formInput={type.form}
                submitHandler={submitChange}
                button={t("submit")}
                isOpenHandler={setIsAcceptedHandler}
                isOpen={true}
                plainForm={false}
              ></AutoForm>
            </Row>
          )
        : null}
    </RowContainer>
  );
};

export default Layout(Contact);
