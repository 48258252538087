import styled from "styled-components";
export const SelectBox = styled.div`
  position: relative;
  min-width: 220px;
  button {
    outline: none !important;
    width: 100%;
    border-radius: 10px;
    border: 1px solid silver;
    height: 50px;
    margin: 7px 0;
    padding-left: 1em;
    font-weight: 700;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
    color: black;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &.filter button {
    min-height: 40px;
    height: 40px;
    margin: 4px 0;
  }
  &.filter {
    margin-right: 0.5em;
  }
  button label {
    display: none;
  }
  button.has {
    padding-top: 13px;
  }
  button.has label {
    position: absolute;
    font-size: 10px;
    top: 16px;
    display: block;
    font-weight: normal;
  }
  &.filter button.has label {
    font-size: 10px;
    top: 12px;
  }
  &.filter .options {
    top: 31px;
  }
  &.open i {
    transition: all 0.2s ease-out 0s;
    transform: rotate(180deg);
  }
  button i {
    margin-left: auto;
    margin-right: 1em;
  }
  .options {
    min-height: 100px;
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    background-color: white;
    border: 1px solid rgb(224, 224, 227);
    border-radius: 12px;
    list-style: none;
    padding: inherit;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 42px;
    transition: all 1s ease-out 0s;
  }
  .options li.true {
    color: ${(props) => props.theme.background};
    font-weight: 500;
    border-bottom: 1px solid rgb(224, 224, 227);
  }
  .options li {
    cursor: pointer;
    border-bottom: 1px solid rgb(224, 224, 227);
    padding: 10px 0;
    margin: 0 10px;
  }
  .options li:last-child {
    border-bottom: 0px solid rgb(224, 224, 227);
  }
  .options li:hover {
    color: ${(props) => props.theme.background};
  }
`;
export const Label = styled.label`
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 14px;
  font-size: 12px;
  transition: all 0.1s ease;
  color: ${(props) => props.theme.foreground};
  &.shrink {
    display: none;
  }
`;
