import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExternalIcon, LogoutIcon } from "../../../../icons";
import { clearVehicle } from "../../../../store/actions/vehicle";
import { ColumnContainer, LinkButton, RowContainer } from "../../../styles/containers/styles";
import { PageHead } from "../../../styles/headings";
import Layout from "../../common/layout";
const Settings = (props) => {
  // Use the useTranslation hook from react-i18next to handle translations

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //to update the page title
  useEffect(() => {
    document.title = `${t("settings")} - Ticket App | Innopark24`;
  }, [t]);

  return (
    <RowContainer>
      <PageHead>{t("settings")}</PageHead>
      <ColumnContainer className="left">
        <LinkButton target="_blank" to="https://www.innopark24.de/privacy">
          <ExternalIcon></ExternalIcon>
          {t("privacy")}
        </LinkButton>
        <LinkButton target="_blank" to="https://www.innopark24.de/imprint">
          <ExternalIcon></ExternalIcon>
          {t("imprint")}
        </LinkButton>
        <LinkButton
          to="/"
          onClick={() => {
            dispatch(clearVehicle());
            navigate("/");
          }}
        >
          <LogoutIcon></LogoutIcon>
          {t("logout")}
        </LinkButton>
      </ColumnContainer>
    </RowContainer>
  );
};

// exporting the page with parent container layout..
export default Layout(Settings);
