import React, { useRef } from "react";
import CustomSelect from "../select";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CheckBox, DatetimeInput, FileContainer, Input, InputContainer, Label, TextArea } from "./styles";
import { ErrorMessage } from "../form/styles";
import { TickIcon, UploadIcon } from "../../../icons";

function FormInput(props) {
  // Initialize translation function for current language
  const { t } = useTranslation();
  // Create a reference to file input element
  const fileInputRef = useRef(null);
  // Get theme colors from Redux store
  const themeColors = useSelector((state) => state.themeColors);

  switch (props.type) {
    // Render a regular text input
    case "text":
    case "password":
    case "number":
    case "email":
      return (
        <InputContainer animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input autoComplete="on" theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type,props.sub)} />
          {/* {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>} */}
        </InputContainer>
      );
    // Render a time input with time picker
    case "time":
      let userFriendlyTime = new Date(props.value);
      return (
        <InputContainer>
          <DatetimeInput
            theme={themeColors}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            selected={userFriendlyTime}
            dateFormat="h:mm aa"
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholder={t(props.placeholder)}
            type={props.type}
            onChange={(event) => props.onChange(event, props.id, props.type)}
          />
          <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
            {t(props.label)}
          </Label>
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a date input with date picker
    case "date":
      let userFriendlyDate = new Date(props.value);
      return (
        <InputContainer>
          <DatetimeInput theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={props.type} selected={userFriendlyDate} onChange={(event) => props.onChange(event, props.id, props.type)} />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a datetime input with date and time pickers
    case "datetime":
      let userFriendlyDateTime = props.value.length > 0 ? moment(props.value).format("dddd, MMMM Do YYYY, h:mm A") : "";
      return (
        <InputContainer>
          <DatetimeInput theme={themeColors} showTimeSelect className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={props.type} value={userFriendlyDateTime} onChange={(event) => props.onChange(event, props.id, props.type)} />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a file and image
    case "image":
    case "file":
      function formatSize(sizeInBytes) {
        if (sizeInBytes < 1024) {
          return sizeInBytes + " bytes";
        } else if (sizeInBytes < 1024 * 1024) {
          return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else {
          return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        }
      }
      const size = formatSize(props.value[0] ? props.value[0].size : 0);
      const handleButtonClick = () => {
        fileInputRef.current.click();
      };
      return (
        <FileContainer>
          <button onClick={handleButtonClick}>
            <UploadIcon />
            {t("upload", { label: t(props.label) }) + (props.required ? " *" : "") + (props.value.length > 0 ? ` : ${props.value[0].name} (${size})` : "")}
          </button>
          <Input
            ref={fileInputRef}
            style={{ display: "none" }}
            theme={themeColors}
            accept={props.type === "image" ? `image/*` : ``}
            className={`input ${props.value.length > 0 ? "shrink" : ""}`}
            placeholder={t(props.placeholder)}
            type={`file`}
            onChange={(event) => props.onChange(event, props.id, props.type)}
          />
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }} />}
        </FileContainer>
      );
    // Render a textarea
    case "textarea":
      return (
        <InputContainer className="textarea">
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <TextArea theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} value={props.value} onChange={(event) => props.onChange(event, props.id)} />
        </InputContainer>
      );
    // Render a submit button
    case "submit":
      return (
        <Button theme={themeColors} className="submit" disabled={props.disabled} type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a close button
    case "close":
      return (
        <Button theme={themeColors} className="close" type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a cehckbox
    case "checkbox":
      return (
        <InputContainer className="checkbox">
          <Label className="checkbox" theme={themeColors}>
            <CheckBox
              theme={themeColors}
              type={props.type}
              checked={props.value}
              onChange={() => {
                props.onChange(!props.value);
              }}
            ></CheckBox>
            <span dangerouslySetInnerHTML={{ __html: t(props.placeholder) }}></span>
          </Label>
        </InputContainer>
      );
    // Render a select box
    case "select":
      return <CustomSelect theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></CustomSelect>;
    default:
      return <></>;
  }
}
export default FormInput;
