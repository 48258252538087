// its middlewear to handle reducer call to update a state

import { getData } from "../../../backend/api";

const fetchVehicle = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_VEHICLE_LOADING",
    });
    getData(data, "validate")
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "FETCH_VEHICLE_SUCCESS",
            payload: response.data,
          });
        } else {
          dispatch({
            type: "FETCH_VEHICLE_ERROR",
            payload: "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_VEHICLE_ERROR",
          payload: error,
        });
      });
  };
};
const clearVehicle = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_VEHICLE",
    });
  };
};
export { fetchVehicle, clearVehicle };
