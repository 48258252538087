import styled from "styled-components";

export const Grouping = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 200px;
`;
export const Group = styled.div`
  display: flex;
  padding: 12px 10px;
  cursor: pointer;
  border-radius: 10px 0 0 10px;
  background:white;
  margin-bottom:2px;
  transition: 0.5s;
  &.active, &:hover {
    background-color: ${(props) => props.theme.themeBackground};
    color: ${(props) => props.theme.themeForeground};
    margin-left: -5px;
    padding: 16px 10px;
  }
`;
export const FaqList = styled.div`
  display: flex;
  flex: auto;
  flex: 1 1 calc(100% - 200px);
  flex-direction: column;
  background: white;
  border-radius: 10px;
  margin: 0em 0;
  border-top-left-radius: 0;
  padding-top:10px;
  border-left: 5px solid #1381c5;
`;
export const FaqItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0.5em 1em;
  border-radius: 10px;
  background: #f3f8fb;
`;
export const Question = styled.div`
  color: #1381c5;
  display: flex;
  flex: auto;
  flex: 1 1 100%;
  padding: 10px 0;
  border-bottom: 1px solid white;
`;
export const Answer = styled.div`
  display: flex;
  flex: auto;
  flex: 1 1 100%;
  padding: 10px 0;
`;
